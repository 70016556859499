import './App.css';
// import { makeStyles } from '@material-ui/core/styles';
// import { Button, Box, AppBar, Toolbar } from '@material-ui/core';
// import { Grid } from '@mui/material'; 
import PaymentButton from './PaymentButton';
function App() {

  // const useStyles = makeStyles({
  //   button: {
  //     margin: 10,
  //   },
  // });

  // const classes = useStyles();

  return (
    <div className="App">
      <PaymentButton />
    </div>
  );
}

export default App;
