/* global ApplePaySession */

import React, { useEffect, useState } from 'react';
import Log from './Log';
import axios from 'axios';

const PaymentButton = () => {
    const [showButton, setShowButton] = useState(false);
    const [logs, setLogs] = useState([]);

    const addLog = (message) => {
        setLogs((prevLogs) => [...prevLogs, message]);
    };

    const handlePayment = async () => {
        if (window.PaymentRequest) {
            addLog('Payment Request API is supported');
            const supportedPaymentMethods = [
                {
                    supportedMethods: 'https://apple.com/apple-pay',
                    data: {
                        version: 3,
                        merchantIdentifier: 'merchant.com.dash-hk.evenue',
                        merchantCapabilities: ['supports3DS', 'supportsCredit', 'supportsDebit'],
                        supportedNetworks: ['visa', 'masterCard'],
                        countryCode: 'HK',
                    },
                },
            ];

            const paymentDetails = {
                total: {
                    label: 'Total',
                    amount: { value: '10.00', currency: 'HKD' },
                },
            };

            const paymentRequest = new PaymentRequest(supportedPaymentMethods, paymentDetails);
            paymentRequest.onmerchantvalidation = async event => {
                addLog('onmerchantvalidation');
                addLog(event.validationURL); 
                try {
                    const sessionPromise = await axios.post("https://asia-east2-dash-ticketing-poc.cloudfunctions.net/api/payment/apple/merchant-verification", {validationUrl: event.validationURL});
                    addLog(JSON.stringify(sessionPromise.data));
                    event.complete(sessionPromise.data);
                    
                }catch(err){
                    addLog(err);
                }
                // Call your own server to request a new merchant session.
                //const merchantSessionPromise = validateMerchant();
                //event.complete(merchantSessionPromise);
            };
            paymentRequest.onpaymentauthorized = async event => {
                addLog('onpaymentauthorized');
                addLog(event);
            }
            const canPayWithApplePay = await paymentRequest.canMakePayment();
            if(canPayWithApplePay){
                addLog('can pay with apple pay');
            }else{
                addLog('can not pay with apple pay');
            }
            // try { 
            //     const paymentResponse = await paymentRequest.show();
            //     const result = await axios.post("https://asia-east2-dash-ticketing-poc.cloudfunctions.net/api/payment/apple/process-payment", {paymentResponse: paymentResponse});
            //     addLog(JSON.stringify(result));
            //     const status = "success";
            //     paymentResponse.complete(status);
            // } catch (err) {
            //     addLog("==paymentResponse==");
            //     addLog(err);
            // }
            await paymentRequest.show().then( async (paymentResponse) => {
                // TODO: Process payment
                addLog("\n\n");
                addLog("===paymentResponse===");
                addLog("\n\n");
                addLog(JSON.stringify(paymentResponse));  
                try{
                    const result = await axios.post("https://asia-east2-dash-ticketing-poc.cloudfunctions.net/api/payment/apple/process-payment", {paymentResponse: paymentResponse});
                    addLog(JSON.stringify(result));
                }catch(err){
                    addLog(err);
                }
                // paymentResponse.complete('success');
            }).catch((error) => {
                addLog('Payment Request API error:', JSON.stringify(error));   
                console.error('Payment Request API error:', error);
            });

        } else if (window.ApplePaySession) {
            addLog('Apple Pay JS API is supported');
            const request = {
                countryCode: 'HK',
                currencyCode: 'HKD',
                supportedNetworks: ['visa', 'masterCard'],
                merchantCapabilities: ['supports3DS'],
                total: { label: 'D-ASH', amount: '10.00' },
            };

            const session = new ApplePaySession(3, request);

            session.onvalidatemerchant = (event) => {
                // TODO: Validate the merchant
                addLog(event);

                const merchantSession = {}; // Replace with actual merchant session
                session.completeMerchantValidation(merchantSession);
            };

            session.onpaymentauthorized = (event) => {
                // TODO: Handle authorized payment
                session.completePayment(ApplePaySession.STATUS_SUCCESS);
            };

            session.begin();
        } else {
            console.log('Both Payment Request API and Apple Pay JS API are not supported');
        }
    };

    useEffect(() => {
        if (window.PaymentRequest || window.ApplePaySession) {
            setShowButton(true);
        }
    }, []);

    return (
        <div>
            {showButton && <div id="pay-button" onClick={handlePayment} className="apple-pay-button apple-pay-button-black"></div>}
            <Log logs={logs} />{}
        </div>
    );
};

export default PaymentButton;
