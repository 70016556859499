import React from 'react';

const Log = ({ logs }) => (
    <div className="logs">
        {logs.map((log, index) => (
            <div key={index}>{log}</div>
        ))}
    </div>
);

export default Log;